<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>
    <div class="writer-box-content" style="height: auto;min-height: calc(100% - 10rem);background: #FAFAFD;">

      <!-- steps -->
      <StepsWriter :active="0"/>

      <!-- elements -->
      <div class="hidden-xs-only" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        故事设置
      </div>
      <div class="writer-box-content-editor">
        <StoryIdeaDialog v-if="storyTiaojian.length>0" :enums="enums" channel="page_gen"  ref="dpSettingRef"/>
      </div>

    </div>

    <!-- footer -->
    <div class="writer-box-footer" style="width: 100%;">
      <span></span>
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="nextStep" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_ZHIHU'">下一步</el-button>
      </span>
    </div>

  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import StepsWriter from '@/components/StepsWriterZhihu'
import * as EnumApi from '@/api/enums'
import { mapState, mapMutations} from 'vuex'
import StoryIdeaDialog from '@/components/StoryIdeaDialog/index_dp'
export default {
  name: 'Article',
  components: {
    HeadNavWriter, StepsWriter, StoryIdeaDialog
  },
  data() {
    return {
      enums: {},
      intervalId: null,
      isLoading: true,
    	targetPermit: 'article'
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag', 'activeTemplate', 'storyTiaojian']),
  },
  mounted() {
    this.handleEnum()
  },
  beforeDestroy() {
    if (this.intervalId) {
      // 清除定时器，停止周期性执行
      clearInterval(this.intervalId);
    }
    this.setActiveTemplate({id: '-1'})
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpFlag', 'setDialogShowCostomContactFlag', 'setActiveTemplate']),
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
        this.intervalId = setInterval(()=>{
          if(this.$refs['dpSettingRef']){
            // console.log('nextBtnDisabled', this.$refs['dpSettingRef'].nextBtnDisabled, this.$refs['dpSettingRef'].activeItem, this.$refs['dpSettingRef'].storyTemplateAnswers);
            let currentTemplate = this.$refs['dpSettingRef'].activeItem
            let storyTemplateAnswers = this.$refs['dpSettingRef'].storyTemplateAnswers
            this.isLoading = currentTemplate.id == '-1'? true : (storyTemplateAnswers.some(item => !item || item.trim() == '') && Number(currentTemplate.id)>0)
          }
        }, 1000);
        console.log('this.activeTemplate', this.activeTemplate, this.$refs['dpSettingRef']);
        if(this.$refs['dpSettingRef'] && this.activeTemplate.id != '-1'){
          this.$refs['dpSettingRef'].handleOkFromHomePage()
        }
      })
    },
    nextStep(){
      console.log(this.$refs['dpSettingRef']);
      this.$refs['dpSettingRef'].createOrUpdateStroy(0)
    }
  }
}
</script>
<style>
.ly-tooltip-class .el-tooltip__popper {
  background: rgba(0,0,0,0.8) !important;
  border-radius: 20px !important;
}
.ly-tooltip-class .el-tooltip__popper[x-placement^=top] .popper__arrow:after{
    border-top-color:  #595959 !important;
}
</style>
<style scoped lang="scss">
.ly-bg-color {

}
</style>
