<template>

    <!-- 从创意开始 弹窗 -->
    <el-drawer
      class="custom-dialog"
      ref="dialog"
      title=""
      :visible.sync="showDialogCdpFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="false"
      :withHeader="showHeader"
      :destroy-on-close="true"
      :show-close="showHeader"
      :wrapperClosable="false"
      :size="drawerWidth"
      @close="close"
      >
      <div style="padding: 1.5rem">
        <el-row :gutter="10">
          <el-col>
            <div class="d-flex flex-between text-center slider-box" style="padding: .1rem;">
              <div class="slider-box-item" :class="{'slider-box-active': sliderBoxActive == 0}" @click="sliderBoxActive = 0">简单模式</div>
              <div class="slider-box-item" :class="{'slider-box-active': sliderBoxActive == 1}" @click="sliderBoxActive = 1">专家模式</div>
            </div>
          </el-col>
          <el-col :span="24" class="my-1">
            <span class="field-title">故事类型</span><span class="field-required">*</span>
          </el-col>
          <el-col :span="24" v-if="storyTiaojian.length>0">
            <el-cascader class="custom-select-v1" v-if="storyTiaojian.length>0"
                v-model="articleTypeIdOptions"
                placeholder="类型"
                :options="storyTiaojian.filter(item1=>item1.code==1)[0].children.filter(item2=>item2.code==8)[0].children"
                @change="$handleChangeArticleTypeId($event, story)"
                :props="{
                  expandTrigger: 'hover'
                }"
                :show-all-levels="false"
              filterable></el-cascader>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="my-1">
          <el-col :span="24" class="my-1">
            <span class="field-title">写作视角</span><span class="field-required">*</span>
          </el-col>
          <el-col :span="24">
            <el-radio-group v-model="story.writingPerspective">
              <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.WritingPerspectiveEnum">{{item.desc}}</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="my-1" v-show="sliderBoxActive == 0">
          <el-col :span="24" class="my-1">
            <span class="field-title">你想要创作什么样的故事？</span><span class="field-required">*</span>
          </el-col>
          <el-col :span="24">
            <el-input type="textarea" placeholder="请描述你想创作的故事…可以是简单的一句话或者是一大段设定"
              v-model="story.storyIdea" :rows="4" maxlength="1000" show-word-limit clearable class="story-idea no-border-input">
            </el-input>
          </el-col>
        </el-row>

        <!-- 创意引导 -->
        <el-row :gutter="10" class="my-1"  v-show="sliderBoxActive == 1">
          <el-col :span="24" class="my-1">
            <span class="field-title">故事元素</span><span class="field-required"></span>
          </el-col>
          <el-col :span="24">
            <el-input type="text" placeholder="非必填，多个用逗号分隔"
              v-model="story.storyIdeaElement" :rows="1" clearable class="story-idea no-border-input">
            </el-input>
            <div class="d-flex flex-between">
             <div class="story-idea-tag-box">
               <span @click="handleIdeaClick('element', itemHot.name)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in synesthesiaElements">{{itemHot.name}}</span>
             </div>
             <div class="pointer get-more" @click="handleIdeaRef('element')">
               <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
             </div>
            </div>
          </el-col>

          <el-col :span="24" class="my-1" v-if="story.articleGenType != 'GEN_BY_ZHIHU'">
            <span class="field-title">人设CP</span>
          </el-col>
          <el-col :span="24" v-if="story.articleGenType != 'GEN_BY_ZHIHU'">
            <el-input type="text" placeholder="非必填"
              v-model="story.storyCp" clearable :rows="1" class="story-idea no-border-input">
            </el-input>
            <div class="d-flex flex-between">
              <div class="story-idea-tag-box">
                <span @click="handleIdeaClick('cp', itemHot.leadingRole1)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in cps">{{itemHot.leadingRole1}}</span>
              </div>
              <div class="pointer get-more" @click="handleIdeaRef('cp')">
                <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
              </div>
            </div>
          </el-col>

          <el-col :span="24" class="my-1">
            <span class="field-title">时下热点</span><span class="field-required"></span>
          </el-col>
          <el-col :span="24">
            <el-input type="text" placeholder="可选，多个用逗号分隔"
              v-model="story.storyHot" clearable :rows="1" class="story-idea no-border-input">
            </el-input>

            <div class="d-flex flex-between">
              <div class="story-idea-tag-box">
                <span @click="handleIdeaClick('hot', itemHot.content)" class="story-idea-tag-box-tag pointer" :key="index" v-for="(itemHot,index) in hots">{{itemHot.content}}</span>
              </div>
              <div class="pointer get-more" @click="handleIdeaRef('hot')">
                <img src="@/assets/random.png" style="width: 12px;padding-right: .25rem;"/><span style="">换一换</span>
              </div>
            </div>
          </el-col>

          <el-col :span="24" class="my-1">
            <span class="field-title">其它想法</span>
          </el-col>
          <el-col :span="24">
            <el-input type="textarea" placeholder="请描述你想创作的故事…可以是简单的一句话或者是一大段设定"
              v-model="story.storyIdeaOther" clearable :rows="4" maxlength="1000" show-word-limit class="story-idea no-border-input">
            </el-input>
          </el-col>
        </el-row>

        <el-row :gutter="10" type="flex" justify="center" style="margin-top: 2rem; margin-bottom: 1rem;">
          <el-button class="" style="padding: 10px 55px" @click="close()">取 消</el-button>
          <el-button class="" style="padding: 10px 55px;margin-left: 30px" type="primary" @click="newStoryByIdea">开 始</el-button>
        </el-row>
      </div>
    </el-drawer>
</template>
<script>
import * as StoryCpApi from '@/api/storycp'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import * as ArticleApi from '@/api/article'
export default {
  name: 'StoryByIdea',
  props: {
    enums: {
      type: Object,
      default: {}
    },
    channel: {
      type: String,
      default: 'workspace_index'
    },
  },
  components: {

  },
  data() {
    return {
      sliderBoxActive: 0,
      synesthesiaElements:[],
      cps:[],
      hots:[],
      articleTypeIdOptions: []
    }
  },
  mounted() {
    console.log('mounted');
    this.articleTypeIdOptions = [this.story.articleTypeId]
    this.handleIdeaRef('element')
    this.handleIdeaRef('hot')
    this.handleIdeaRef('cp')
    window.addEventListener('resize', this.forceUpdate);

    this.$nextTick(() => {
      if ('workspace_index' == this.channel) {// 初始化表单
        this.sliderBoxActive = 0
        console.log('StoryIdeaDialog init')
        this.setStory({id: '', articleType: 'TANBI_DOUBLE_BOY', articleGenType: 'GEN_BY_IDEA', writingPerspective: 'FIRST_PERSON', storyCp: '', storyHot: '', storyIdeaElement: ''})
      }
    })
  },
  computed: {
    ...mapState(['user', 'story', 'showDialogCdpFlag', 'storyTiaojian']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : '40%';
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogCdpFlag']),
    close(){
      this.setShowDialogCdpFlag(false);
    },
    newStoryByIdea() {
      if (!this.story.articleTypeId || !this.story.articleTypeId.trim() || Number(this.story.articleTypeId)==0 ) {
          this.$message.error('请选择故事类型');
          return false;
      }
      if (!this.story.writingPerspective || !this.story.writingPerspective.trim()) {
          this.$message.error('请选择写作视角');
          return false;
      }
      if (this.sliderBoxActive == 0 && (!this.story.storyIdea || !this.story.storyIdea.trim())) {
          this.$message.error('请输入故事创意');
          return false;
      }

      if (this.sliderBoxActive == 0) {
          this.story.storyCp = ''
          this.story.storyHot = ''
          this.story.storyIdeaElement = ''
          this.story.storyIdeaOther = ''
      }else if (this.sliderBoxActive == 1) {
          this.story.storyIdea = ''
      }
      console.log('channel', this.channel, 'story', this.story);
      if ('genbyidea_regenelement' == this.channel) {// 重新生成 要素
        let params = {}
        if(this.sliderBoxActive == 0){// 快捷模式
          params = {
            id: this.story.id,
            articleType: this.story.articleType,
            articleTypeId: this.story.articleTypeId,
            writingPerspective: this.story.writingPerspective,
            storyIdea: this.story.storyIdea
          }
        }else if(this.sliderBoxActive == 1){// 专家模式
          params = {
            id: this.story.id,
            articleType: this.story.articleType,
            articleTypeId: this.story.articleTypeId,
            writingPerspective: this.story.writingPerspective,
            storyIdeaElement: this.story.storyIdeaElement,
            storyCp: this.story.storyCp,
            storyHot: this.story.storyHot,
            storyIdeaOther: this.story.storyIdeaOther
          }
        }
        this.close()
        ArticleApi.update(params).then(res => {
          let data = res.data.data
          this.setStory(data)
          setTimeout(()=>{
            this.$parent.genStoryElement()
          }, 300)
        })

        return false;
      }

      ArticleApi.create(this.story).then(res => {
          if(res.data.code == 0){
            let data = res.data.data
            console.log('new cdp story', data)
            this.setStory(data)
            this.close()
            this.$router.push('/workspace/genbyidea');
          }else{
            this.$message.error(res.data.msg);
          }
      })
    },
    handleIdeaRef(flag) {
      if(flag == 'element') {
        StorySynesthesiaElementApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.synesthesiaElements = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'hot') {
        StoryHotApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.hots = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'cp') {
        StoryCpApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.cps = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }
    },
    handleIdeaClick(flag, text) {
      console.log('flag, text', flag, text);
      if(flag == 'element') {
        if(!this.story.storyIdeaElement){
          this.story.storyIdeaElement = text
        }else if(this.story.storyIdeaElement.indexOf(text) == -1){
          this.story.storyIdeaElement = this.story.storyIdeaElement +  ',' + text
        }
      }else if(flag == 'hot') {
        if(!this.story.storyHot){
          console.log('flag, text', flag, text);
          this.story.storyHot = text
          console.log('storyHot', this.story.storyHot);
          this.setStory(this.story)
        }else if(this.story.storyHot.indexOf(text) == -1){
          console.log('flag, text', flag, text);
          this.story.storyHot = this.story.storyHot +  ',' + text
        }
      }else if(flag == 'cp') {
        if(!this.story.storyCp){
          this.story.storyCp = text
        }else if(this.story.storyCp.indexOf(text) == -1){
          this.story.storyCp = this.story.storyCp +  ',' + text
        }
      }
    },
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            // 生成从0到i的随机索引
            const j = Math.floor(Math.random() * (i + 1));
            // 交换元素
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
$tagMT: .5rem;
/deep/ .story-idea .el-textarea__inner{
  background: #F4F4F4;
  padding: 1rem;
}
.story-idea-tag-box {
  margin-top: $tagMT;
  &-tag {
    display: inline-block;
    background: #F2F3FF;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    padding: .25rem 1rem;
    margin: 0 .5rem .5rem 0;
    white-space: nowrap;
  }
}
.get-more {
  font-size: 12px;
  color: #0052D9;
  white-space: nowrap;
  margin-top: $tagMT;
}
.custom-select-v1 {
  /deep/ .el-input__inner {
    border-radius: 20px;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    background: #FFFFFF;
    border: 1px solid #EBEEF5 !important;
  }
  /deep/ .el-input__suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  /deep/ .el-input--suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
</style>
